@font-face {
    font-family: 'Akkurat-light';
    src:local('Akkurat-light'),
        url(../font/FontsFree-Net-Akkurat-Light.ttf);

    font-family: 'Akkurat';
    src:local('Akkurat'),
        url(../font/FontsFree-Net-Akkurat.ttf);
}
@font-face {
    font-family: 'Akkurat-light-italic';
    src:local('Akkurat-light-italic'),
        url(../font/Akkurat-LightItalic.ttf);
}
@font-face{
    font-family:"revicons";
    fallback:fallback;
    src:url(../font/revicons.woff) format('woff'),
        url(../font/revicons.ttf) format('ttf'),
        url(../font/revicons.eot) format('eot')
}
.contenedor{
    padding: 100px 20px 0;
}
.btn{
    display: flex;
    align-items: center ;
    justify-content: center;
    background-color: unset;
    font-family:'Akkurat-light', sans-serif;
    border: none;
    font-weight: 200;
    color: #fff;
    font-size: 1.3em;
    margin-top: 30px;
    margin-bottom: 20px;
}
.btn::before{
    content:"\e824";
    font-size:20px;
    color:#fff;
    display:block;
    font-family:"revicons";
    padding-right: 10px;

}
/* .btn::before{
    font-size:20px;
    color:#fff;
    display:block;
    font-family:revicons;
} */

.prodImg{
    width: 90vw;
    height: 90vw;
    object-fit: cover;
}
.contPR{
    padding-bottom: 30px;
}
.nombreProd{
    padding: 5px 0;
    text-transform: uppercase;
    font-weight: 200;
    border-bottom: solid 1px #fff;
    width: 90vw;
    margin-bottom: 30px;
}
.infoProd{
    padding-left: 0;
    padding-bottom: 30px;
}
.infoProd li{
    font-family: 'Akkurat-light', sans-serif;
    list-style: none;
    font-weight: 200;
    font-size: 1.3em;
    padding-bottom: 5px;

}
.infoProd li span{
    font-family: 'Akkurat', sans-serif;
text-transform: uppercase;  
font-weight: 400;  
}
.productos{
    overflow:scroll;
    display: flex;
    gap: 20px;
    padding-bottom: 10px;
    margin-top: 30px;
    margin-bottom: 50px;

}

.productos::-webkit-scrollbar {
    display: none;
}

.productos{
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* 
////////////////////
TERMINACIONES
///////////////////
 */
.terminaciones h2{
    font-family: 'Akkurat-light', sans-serif;
    text-transform: uppercase;
    font-size: .8em;
    font-weight: 200;
}

 /* 
////////////////////
PRODUCTOS RELACIONADOS
///////////////////
 */

.pRelacionados{
    text-decoration: none;
    color: white;
}
.pRelacionados img{
    width: 200px;
    height: 200px;
    object-fit: cover;
}
.pRelacionados h3{
    font-family: 'Akkurat-light', sans-serif;
    text-transform: uppercase;
    font-size: .8em;
    font-weight: 200;
    padding-top: 5px;
}

@media(min-width:1024px){
    .contenedor{
        display: flex;
        max-width: 1200px;
        gap: 40px;
        margin: auto;
        margin-bottom: 50px;
        padding-top: 130px;
        height: 100%;
    }
    .btn{
        font-size: 1.1em;
        margin-top: -30px;
    }
    .prodImg{
        width: 550px;
        height: 550px;
        object-fit: cover;
    }
    .contInfo{
        width: 500px;
    }
    .nombreProd{
        width: auto;
    }
    .pRelacionados img{
        width: 120px;
        height: 120px;
        object-fit: cover;
    }
}