@font-face {
    font-family: Akkurat-light;
    src:local('Akkurat-light'),
        url(../../font/FontsFree-Net-Akkurat-Light.ttf)       

}

.buttonShort{
    background-color: white;
    border: unset;
    padding:5px 10px;
    border-radius: 5px;
    font-family: 'Akkurat-light', sans-serif;
    font-size: 18px;
    text-decoration: none;
    color: black;

}
.buttonShort:hover{
    background-color: black;
    color: white;
    transition: all 300ms ease-in-out;
}