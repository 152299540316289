@font-face {
    font-family: 'Akkurat-light';
    src:local('Akkurat-light'),
        url(../../font/FontsFree-Net-Akkurat-Light.ttf);

    font-family: 'Akkurat';
    src:local('Akkurat'),
        url(../../font/FontsFree-Net-Akkurat.ttf);
}
@font-face {
    font-family: 'Akkurat-light-italic';
    src:local('Akkurat-light-italic'),
        url(../../font/Akkurat-LightItalic.ttf);
}
footer{
    height: 60vh;
    padding: 80px 20px;
    background-image: url(../../../public/img/footer-img.png);
    background-position: center;
    background-repeat: no-repeat;
    /* background-size:contain; */
    max-width: 1200px;
    margin: auto;
    position: relative;
}
.ftitle{
    font-size: 3.8em;
    font-weight: 200;
    line-height: 60px;
    margin-top: -30px;
    text-transform: uppercase;

}
.ftitle span{
    font-family: 'Akkurat-light-italic', sans-serif;

}
.icons{
    display: flex;
    position: absolute;
    width: 90%;
    bottom: 40px;
    margin: auto;
    /* padding-top: 130px; */
    justify-content: space-between;
    align-items: baseline;
}
.redes img{
    padding-right: 15px;
    width: 35px;
}
.iso img{
    height: 100px;
}
.mail{
    font-family: 'Akkurat-light ', sans-serif;
    font-weight: 100;
    color: white;
    text-decoration: none;
    font-size: 18px;
    letter-spacing: 1px;   


}
@media(min-width:1024px){
    footer{

    }
}