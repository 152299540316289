@font-face {
    font-family: 'Akkurat-light';
    src:local('Akkurat-light'),
        url(../../font/FontsFree-Net-Akkurat-Light.ttf);

    font-family: 'Akkurat';
    src:local('Akkurat'),
        url(../../font/FontsFree-Net-Akkurat.ttf);
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    transition: all .5s ease;
}

.cActive {
    background-color: black;
}

.logo {
    width: 160px;
    animation: slide-in-left 2s ease;
}

.contIcon {
    border: 1px solid #fff;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    position: relative;
}

.topBar {
    position: absolute;
    top: 18px;
    left: 10px;
    height: 1px;
    background-color: white;
    width: 30px;
    transition: all .4s ease;
}

.bottomBar {
    position: absolute;
    top: 30px;
    left: 10px;
    height: 1px;
    background-color: white;
    width: 30px;
    transition: all .4s ease;
}

.topBarActive {
    transform: rotate(45deg);
    top: 25px;
}

.bottomBarActive {

    transform: rotate(-45deg);
    top: 25px;
}

.links {
    position: absolute;
    width: 100vw;
    z-index: -1;
    top: -100vh;
    height: 100vh;
    transition: all .5s ease;
}

.links ul {
    font-family: 'Akkurat-light',  sans-serif;
    padding: 60px 20px;
    margin: 0;
}

.links ul li {
    font-family: 'Akkurat-light',  sans-serif;
    list-style: none;
    padding-bottom: 20px;

}

.links .link {
    font-size: 1.5em;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    font-family: 'Akkurat-light',  sans-serif;
    transition: all .5s ease;

    

}
.links .link:hover{
    font-family: 'Akkurat',  sans-serif;

    
}

.linkActive {
    font-family: 'Akkurat', sans-serif;
    font-size: 1.5em;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
}

.active {
    background-color: black;
    top: 90px;
    
}

@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(100px);
        transform: translateX(-200px);
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@media(min-width:1024px) {
    .contIcon {
        display: none;
    }
    nav{

        padding-bottom: 20px ;
        background-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0)),
                         -webkit-linear-gradient(left, rgba(255,0,0,0), rgb(255, 255, 255,0));;
                        

    }
    .navWidth{
        display: flex;
        margin: auto;
        align-items: flex-end;
        max-width: 1200px;

    }
    .logo {
        width: 120px;
    }
    .cActive {
        background-color: unset;
    }
    .links {
        position: unset;
        width: 100vw;
        z-index: unset;
        top: unset;
        height: unset;
        transition:unset;
    }
    
    .links ul {
        padding: 0;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        /* animation: fade-in 500ms ease-in; */
    }
    
    .links ul li {
        font-family:  sans-serif;
        list-style: none;
        font-size: 1em;
        text-transform: uppercase;
        padding-bottom: 20px;
    
    }
    
    
    .links .link {
        font-family: 'Akkurat-light',  sans-serif;
        font-size: 1em;
        text-decoration: none;
        color: white;
    }
    
    .linkActive {
        font-family: 'Akkurat',  sans-serif;
        font-size: 1em;
        text-decoration: none;
        color: white;
    }
    
    .active {
        
        background-color: unset;
        top: unset;
    }
    @keyframes fade-in {
        0% {
            -webkit-transform: translate(100px);
            transform: translatey(-50px);
        }
    
        100% {
            -webkit-transform: translatey(0);
            transform: translatey(0);
        }
    }

}