@font-face {
    font-family: 'Akkurat-light';
    src:local('Akkurat-light'),
        url(../font/FontsFree-Net-Akkurat-Light.ttf);

    font-family: 'Akkurat';
    src:local('Akkurat'),
        url(../font/FontsFree-Net-Akkurat.ttf);
}
@font-face {
    font-family: 'Akkurat-light-italic';
    src:local('Akkurat-light-italic'),
        url(../font/Akkurat-LightItalic.ttf);
}
.whiteFont{
    color: white;
}
.blackFont{
    color: black;
}
.paddingFromBtn{
    padding-bottom: 35px;
}

.page{
    height: 100vh;
    /* background-image: url(/public/img/pisoalta200.jpg); */
    /* background-repeat: no-repeat;
    background-position: center;
    background-size: cover; */
    padding: 0 20px;

}
.container{
    position: relative;
    max-width: 1200px;
    height: 100%;
    margin: auto;
}

.container2{
    padding-top: 150px;
    margin: auto;

}
.greeting{
    display: flex;
    align-items: center;
}
.circle{
    background-color: white;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    margin-right: 7px;
}
.greeting p{
    font-family: Akkurat-light, sans-serif;
}
.title{
    overflow: hidden;
    height: auto;
}
.title h1{
    font-size: 3.3em;
    text-transform: uppercase;
    line-height: 50px;
    margin-top: 20px;
    margin: 0;
    font-weight: 100;
}

.title .titleText{
    font-family: 'Akkurat-light', sans-serif;
    text-transform: uppercase;
    font-weight: 100;
    font-size: 3.3em;
    line-height: 50px;
    opacity: 0;
    transition: all 100ms ease-in;
}
.title .titleTextItalic{
    font-family: 'Akkurat-light-italic', sans-serif;
    text-transform: uppercase;
    font-weight: 100;
    font-size: 3.3em;
    line-height: 50px;

    opacity: 0;
}
.hide{
    opacity: 0;
}
.titleAnimation{
    animation:slide-in-top 1.5s forwards;
}
.title h3{
    text-transform: uppercase;
    font-family: 'Akkurat-light', sans-serif;
    font-weight: 100;
    font-size: 1.5em;
}
.icon{
    position: absolute;
    left: 10px;
    bottom: 80px;

}
.bgFloor{
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: -100;
    overflow: hidden;
}


.img{
object-fit: cover;
height: 100vh;
width: 100vw;

}

/* 
========================
SECCION CLASIC FLOORING
========================
*/

.secP{
    padding: 60px 20px 0px 20px;
    height: 60vh;
}
.containerClassic{
    position: relative;
    max-width: 1200px;
    height: 45vh;
    margin: auto;
    padding-top: 50px;
}

/* .imgClassic{
    width: 70vw;
    height: auto;
    position: absolute;
    z-index: -1;
    top: 250px;
    right: 0;
    /* opacity: 0.8; */

.paralaxContainer{
    width: 70vw;
    height: auto;
    position: absolute;
    z-index: -1;
    top: 200px;
    right: 0;
}
.imgCF{    
    width: 75vw;
    top: 100px;
}

.title .titleText span{
    font-family: 'Akkurat-light', sans-serif;
    text-transform: uppercase; 
}
.infoClasic p{
    font-family: 'Akkurat-light', sans-serif;
    font-size: 22px;
    line-height: 24px;
    font-weight: 100;
    margin: 0;
}

/* 
===============
SECCION ZOCALOS
===============
*/
.bgDarkGray{
    background-color: #242424;
    padding-top: 60px;
}
.containerZocalos{
    position: relative;
    max-width: 1200px;
    height: 55vh;
    margin: auto;
}
.zocaloTitle{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.imgZocalos{
    width: 80vw;
    height: auto;
    position: absolute;
    z-index: -1;
    top: 200px;
    left: -20px;
    opacity: 0.8;
}
/* .infoZocalos{
    display: flex;
    justify-content: space-between;
    align-content: center;
    gap: 30px;
    margin-bottom: 40px;
} */
/* h2{
    font-family: 'Akkurat-light' , sans-serif;
    font-size: 2.5em;
    text-transform: uppercase;
    line-height: 45px;
    font-weight: 100;
    padding-bottom: 40px;
} */
.infoZocalos span{
    font-family: 'Akkurat-light-italic' , sans-serif;
    text-transform: uppercase; 
}
/* .infoZocalos h3{
    font-family: 'Akkurat-light' , sans-serif;
    font-size: 1.2em;
    text-transform: uppercase;
    line-height: 25px;
    font-weight: 100;
} */
.detalleZocalos{
    position: absolute;
    top: 250px;
    right: 0px;
    text-align: center;
}
.detalleZocalos img{
    width: 100px;
}
.detalleZocalos p{
    font-family: 'Akkurat-light' , sans-serif;
    text-transform: uppercase;
    font-weight: 100;
    font-size: 14px;
    margin: 0;
    margin-top: 5px;
}

/* 
===================
SECCION WOOD PANELS
===================
*/

.wall{
    background-color: #E1E1E1;
}
.containerWall{
    height: 450px;
    padding: 0 20px;
    padding-bottom: 100px;
    padding-top: 50px;
}
.infoWall{
    display: flex;
    align-items: flex-end;
    gap:5px;
}

.subTituloSec{
    font-family: 'Akkurat-light' , sans-serif;
    font-size: 1em;
    text-transform: uppercase;
    line-height: 18px;
    font-weight: 100;
}
.wallImgCont{
    position:relative;
}
.wallPic{
    margin-top: 20px;
    width:calc(100vw - 20px);
    height: 230px;
    float: right; 
}

.wallPicMuestraContenedor img{
    position: absolute;
    width: 120px;
    height: 120px;
    border-radius: 100px;
    object-fit: cover;
    top: 210px;
    left: 40px;
}
.giantWall{
    background-color: #c5c4c4;
    padding-top: 50px;
    padding-bottom: 50px;
}
.gWallInfo{
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: black;
    padding: 0 20px 30px;
}

.gWallInfo h3{
    font-family: 'Akkurat-light' , sans-serif;
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: 200;
    line-height: 20px;
    width: 150px;
}
.gWallInfo span{
    font-family: 'Akkurat-light-italic', sans-serif;
}
.gWallPic{
    width: 95vw;
}
.giantWall .button{
    padding: 10px 0 70px 70px;

}
.salto{
    display: none;
}

.wallPic1{
    margin-top: 20px;
    text-align: right;
    width:90vw;
    max-width: 700px;
}
.infoWallSiding{
    display: flex;
    gap: 30px;
    align-items:flex-end;
}

.infoWallSiding span{
font-family: 'Akkurat-light-italic', sans-serif;    
}
.wallPic1{
    object-fit: cover;
    width: 90vw;
    height: 300px;
}


@keyframes slide-in-top {
    0% {
      -webkit-transform: translateX(100px);
      transform: translateY(100px) rotate(5deg);
      opacity: 0;

    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateY(0) rotate(0deg);
      opacity: 1;

    }
  }



@media (min-width:1024px){
    .greeting p{
        font-size: 20px;
    }
    .title h1{
        font-size: 5em;
        line-height: 70px;
    }
    .jump{
        display: none;
    }
    .icon{
        position: absolute;
        left: 10px;
        bottom: 20px;
        
    
    }


    /* 
========================
SECCION CLASIC FLOORING
========================
*/

    .containerClassic{
       height: 90vh;
    }
    .paralaxContainer{
        width: 50vw;
        height: auto;
        position: absolute;
        z-index: -1;
        top: 200px;
        right: 0;
    }
    .imgCF{    
        width: 50vw;
        top: 100px;
    }
    .verticalFrase{
         top: 500px;

    }
    .title .titleTextItalic{
        font-size: 4em;
        text-transform: uppercase;
        line-height: 70px;
        font-weight: 100;
    }
    .title .titleText{
        font-size: 4em;
        text-transform: uppercase;
        line-height: 70px;
        font-weight: 100;
    }
    .infoClasic p{
        max-width: 400px;
    }
        /* 
========================
ZOCALOS DE PVC
========================
*/  
.bgDarkGray{
    width: 100vw;
    margin: auto;
}
    .infoZocalos{
        justify-content: space-between;
        padding-bottom: 40vh;
    }

    /* .infoZocalos h3{
        font-size: 2em;
        line-height: 30px;
        width: 300px;
        font-weight: 100;

    } */
    .secP{
        height: 70vh;
        padding-bottom: 200px;
    }
    .imgZocalos{
        width: 50vw;
        left: 160px;
    
    }
    .detalleZocalos{
        top: 450px;
        right: 200px;
        text-align: center;
    }
    .detalleZocalos img{
        width: 130px;
    }
    .detalleZocalos p{
        font-size: 24px;
    }
    /* 
===================
SECCION WOOD PANELS
===================
*/
    .containerWall{
        max-width: 1200px;
        margin: auto;
        padding-bottom: 300px;

    }
    .wallImgCont{
        position: relative;
    }
    .wallPic{
        position: absolute;
        margin-top: 0px;
        width:40vw;
        max-width: 600px;
        height: 400px;
        top: 0px;
        right: 150px;

    }
    .infoWallSiding{
        display: block;

    }

    .wallPic1{
        position: absolute;
        top: -100px;
        right: 150px;
    }
    .wallPicMuestraContenedor{
        position: absolute;
        top: 10vw;
        right: 30vw;
        
    }
    .infoWall{
        display: flex;
        justify-content:space-between; 
        align-items:center;
        flex-wrap: wrap;
        gap:40px;
        position: relative;
    }

    .subTituloSec{
        font-size:2.5em;
        line-height: 45px;
        max-width: 500px;
        font-weight: 100;
    }
    .salto{
        display: block;
    }
    /* 
===================
Giant wall pannels
===================
*/

    .giantWall{
        /* max-width: 1200px; */
        margin: auto;
        /* padding-top: 150px; */
        text-align: center;
        position: relative;
        padding-left: 90px;
        /* padding-bottom: 90px; */
    }

    .gWallInfo{
        flex-direction: row-reverse;
        gap: 150px;
        text-align: left; 
        padding-bottom: 30px;      
   
    }

    .gWallInfo h3{
        font-size: 2em;
        line-height: 35px;
        width: 270px;
    }
    .gWallPic{
        width: 600px;
        margin-top: -50px;
        margin-left: 80px;
        
    }
    .giantWall .button{
        padding: 10px 0 70px 70px;
    
    }
    .button{
        position: absolute;
        top: 230px;
        left: 0;
    }
    .giantWall .button{
        padding-left: 90px ;
    
    }

    .wallPic1{
        object-fit: cover;
        width: 600px;
        height: 500px;
    }



}

