@font-face {
    font-family: 'Akkurat-light';
    src: local('Akkurat-light'),
        url(../font/FontsFree-Net-Akkurat-Light.ttf);

    font-family: 'Akkurat';
    src: local('Akkurat'),
        url(../font/FontsFree-Net-Akkurat.ttf);
}

@font-face {
    font-family: 'Akkurat-light-italic';
    src: local('Akkurat-light-italic'),
        url(../font/Akkurat-LightItalic.ttf);
}

.contenedor {
    padding: 120px 20px 0;
    max-width: 1200px;
    margin: auto;
}

.contTitular {
    background-color: rgba(255, 255, 255, .16);
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 100;
}

.titular {
    width: 70%;
}

.titular h1 {
    font-family: 'Akkurat-light', sans-serif;
    text-transform: uppercase;
    font-size: 2.8em;
    font-weight: 200;
    line-height: 40px;
    padding-bottom: 10px;
}

.titular h3 {
    font-family: 'Akkurat-light', sans-serif;
    font-weight: 200;
    font-size: .9em;
}

.buttonwidth {
    width: 50px;
}

.destacados {
    display: flex;
    margin: 30px 30px;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
}

.destacado {
    width: 45%;
    padding: 20px 0;
}

.destacado img {
    width: 100px;
    padding-bottom: 10px;
}

.destacado h3 {
    font-family: 'Akkurat-light', sans-serif;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 1em;
}

.infoSec {
    max-width: 500px;
    display: none;
}

.infoSec p {
    font-family: 'Akkurat-light', sans-serif;
    font-weight: 300;
    font-size: 1em;
}

.active {
    display: block;
}

.productos {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    justify-content: left;
    margin-bottom: 40px;
}

.productos a {
    text-decoration: none;
    color: white;
    width: 45%;
}

.pDestacado img {
    width: 165px;
    height: 165px;
    object-fit: cover;
}

.pDestacado h3 {
    font-family: 'Akkurat-light', sans-serif;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 200;
    padding-top: 5px;
}

.btnExpand {
    display: flex;
    border: solid 0.5px;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    font-family: 'Akkurat-light', sans-serif;
    font-size: 18px;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: white;
}

@media (min-width:1024px) {
    .contenedor {
        padding: 150px 20px 0;

    }

    .flexHeader {
        display: flex;
    }

    .contTitular {
        gap: 0px;
        height: 100%;
        max-width: 520px;
    }

    .titular h1 {
        font-size: 4em;
        line-height: 55px;
    }

    .titular h3 {
        font-size: 1.5em;
    }

    .destacados {
        display: flex;
        margin: 0px 30px 30px;
        justify-content: flex-start;
        align-items: flex-start;
        max-height: 250px;
    }

    .destacado img {
        width: 70px;
        padding-bottom: 10px;
    }

    .productos {
        display: flex;
        gap: 25px;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 40px 20px;
    }

    .productos a {
        width: auto;

    }

    .pDestacado img {
        width: 200px;
        height: 200px;
        object-fit: cover;
    }

    .pDestacado h3 {
        width: 200px;

    }
}