@font-face {
    font-family: 'Akkurat-light';
    src:local('Akkurat-light'),
        url(./font/FontsFree-Net-Akkurat-Light.ttf);

    font-family: 'Akkurat';
    src:local('Akkurat'),
        url(./font/FontsFree-Net-Akkurat.ttf);
}
@font-face {
    font-family: 'Akkurat-light-italic';
    src:local('Akkurat-light-italic'),
        url(./font/Akkurat-LightItalic.ttf);
}

body{
    background-color: black;
    color: white;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4{
    padding: 0;
    margin: 0;
    font-family: Akkurat-light, sans-serif;
}
.container{
    height: 100vh;
}

.contNav{
    position: fixed;    
    width: 100vw;
    z-index: 100;

}
.wapContainer{
    position: fixed;
    bottom: 70px;
    right: 20px;
    width: 80px;
    height: 80px;
    background-color: black;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
.wapContainer img{
    width: 50px;
    height: 50px;
    object-fit:fill;
}
@media(min-width:1080px){
    .wapContainer{
 
        right: 100px;

    }

}


